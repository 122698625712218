.myexim-messages {
  display: flex;
  flex-direction: column;
}
.message-user-info {
  margin-bottom: 10px;
  position: relative;
}
.message-user-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.own-message {
  align-self: flex-end;
  background-color: #0E7AFE;
  color: white;
  border-radius: 10px;
  padding: 10px;
}
.other-message {
  align-self: flex-start;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
}
.own-message-text {
  color: white;
  font-weight: bold;
}
.other-message-text {
  color: black;
}
.message-created-at {
  font-size: 12px;
  color: #777;
  bottom: 5px;
  right: 10px;
}
.own-message .message-created-at {
  color: white;
}
.message-text {
  margin-top: 5px;
}

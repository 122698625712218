.promotion-banner {
  background: linear-gradient(180deg, #3395FF 0%, #5DA5F3 100%);
  border-radius: 16px;
  overflow: "hidden";
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin: 10 auto;
  width: 100%;
  box-sizing: border-box;
}
.offer-promotion-banner {
  background: linear-gradient(180deg, #40507E 0%, #000000 100%);
  border-radius: 16px;
  overflow: "hidden";
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin: 10 auto;
  width: 100%;
  box-sizing: border-box;
}
.mobile-promotion-banner {
  background: linear-gradient(180deg, #3395FF 0%, #5DA5F3 100%);
  border-radius: 16px;
  overflow: "hidden";
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin: 10 auto;
  padding-top: 10px;
  width: 100%;
  box-sizing: border-box;
}
.banner-image {
  float: left;
  padding-top: 10px;
  padding-left: 25px;
}
.banner-image img {
  max-width: 75%;
  height: auto;
}
.mobile-banner-image {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 25px;
}
.mobile-banner-image img {
  max-width: 1400%;
  height: auto;
}
.promotion-item {
  padding: 5px;
}
.banner-title {
  max-width: max-content;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: white;
}
.mobile-banner-title {
  max-width: max-content;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: white;
  font-size: 16px;
}
.banner-desc {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  max-width: 800px;
  color: white;
  margin-top: 15px;
  padding-right: 10px;
}
.mobile-banner-desc {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  max-width: 350px;
  color: white;
  margin-top: 5px;
  padding-right: 25px;
}
.avail-now {
  display: inline-flex;
  padding: 10px 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F5993F;
  transition: background-color 0.3s;
  color: white;
  border: #F5993F;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.avail-now:hover {
  background: #f6b06e;
  color: white;
}
.mobile-avail-now {
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F5993F;
  transition: background-color 0.3s;
  color: white;
  border: #F5993F;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 60%;
}
.mobile-avail-now:hover {
  background: #f6b06e;
  color: white;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  color: black;
}
.custom-prev-arrow {
  left: 10px;
}
.custom-next-arrow {
  right: 10px;
}

/* Custom CSS */
.custom-submenu .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Move arrow to the right */
  .custom-submenu .ant-menu-submenu-arrow {
    position: relative; /* Positions the arrow independently */
    margin-bottom: 40px;
  }
  
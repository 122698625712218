
.myexim-table-myactivity-title {
    color: "#434E5B";
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    align-items: center;
    padding-bottom: 20px;
  }
  .back-icon {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 8px;
    color: #007bff; /* Add desired color */
    border-radius: 50%; /* To make the background of the icon circular */
    padding: 5px; /* Adds space around the icon */
  }
  .back-icon-custom {
    position: absolute;
    left: 50px;
    width: 8px;
    height: 14px;
}

  .myexim-table .ant-table-cell {
    font-size: 12px;  /* Change the font size here */
  }

  .status {
    color:#ffffff;
    padding: 3px;
    border-radius: 5px;
    text-align: center;
    font-family: "Inter";
    font-size: 10px;
    white-space: nowrap;
  }
  .status.success{
    background-color: #1ea136;
  }
  .status.failed{
    background-color: #a11e20;
  }

  .ant-modal-content {
    border-radius: 20px;
    /* background: red; */
  }

  .purchase-modal .ant-modal-body {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  .back-icon {
    position: absolute;
    left: 50px;
    width: 8px;
    height: 14px;
    cursor: pointer;
  }
  .modal-title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #434e5b;
  }
  .modal-content {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.description {
  font-size: 12px;
}
.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
}
.counter-wrapper {
  display: flex;
  border-radius: 20px; /* Adjust border radius as needed */
  background-color: #f0f0f0; /* Optional: Light background for the entire counter */
}
.counter-button {
  width: 30px;
  height: 35px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.counter-button:first-child{
  border-radius: 6px 0 0 6px;
}
.counter-button:last-child{
  border-radius: 0 6px 6px 0;
}
.counter-display {
  font-size: 16px;
  height: 35px;
  line-height: 35px;
  border: 2px solid #d0d3d6; 
  padding: 0 10px;
  text-align: center;
  background-color: white;
  width: 50px;
}

.price-details {
  font-size: 12px;
  font-weight: 500;
}
.note-card {
  background-color: #f8f8ff;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 15px;
  border: none;
}
.note-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.info-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.note-title {
  font-size: 14px;
  color: #566aff;
  font-weight: 510;
  font-family: Inter, sans-serif;
  line-height: 26.13px;
}
.note-content {
  font-family: Inter, sans-serif;
  font-size: 11px;
  display: block;
  color: #727a84;
}
.button-container {
  width: 80%;
  margin-top: 20px;
}
.primary-button {
  width: 100%;
  padding: 15px;
  background-color:#007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
}
.secondary-button {
  width: 100%;
  padding: 15px;
  background-color: transparent;
  outline: 2px solid #007bff;
  color: #566aff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}